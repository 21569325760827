import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import ResearchContent from '../components/ResearchContent';

export default function ResearchContentPage({ pageContext, data, location }) {
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  
  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulResearch?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <ResearchContent
          researchData={data?.contentfulResearch}
          bucketname={pageContext?.bucketName}
          researchslug={data?.contentfulResearch?.researchSlug}
          basePath={pageContext.basePath}
          images={data?.contentfulComponentBackgroundImages}
          researchLandingUrl={data?.contentfulCategoryTagsLandingPage?.slug}
          location={location}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query ResearchPageTemplate($contentful_id: String, $locale: String, $partnerId: String) {
    contentfulCategoryTagsLandingPage{
      entryTitle
      slug
      }
    contentfulPartnerSite(partnerId: { eq: $partnerId }) {
      navigation {
        menus {
          menuLink {
            link {
              ... on ContentfulInternalLink {
                id
                referenceToPage {
                  ... on ContentfulCustomResourceHub {
                    id
                    moreResearch {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    contentfulComponentBackgroundImages {
      smallDotMatrixBackgroundImage {
        file {
          url
        }
      }
      leftDonutBackgroundImage{
        file{
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      researchDonutBackgroundImage {
        file {
          url
        }
      }
      researchGridBridgeImage {
        file {
          url
        }
      }
      downloadButtonImage {
        description
        file {
          url
        }
      }
      linkedlnIcon {
        description
        file {
          url
        }
      }
      twitterIcon {
        description
        file {
          url
        }
      }
      emailIcon {
        description
        file {
          url
        }
      }
      facebookIcon {
        description
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            type
          }
        }
      }
    }
    contentfulResearch(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      contentful_id
      entryTitle
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          title
          file {
            url
          }
        }
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      shortDescription {
        raw
      }
      dateOfPublishing
      resourceType
      researchSlug
      researchTitle
      thumbnailImage {
        description
        file {
          url
        }
      }
      shortDescription {
        raw
      }
      headerImage {
        description
        gatsbyImageData(formats: WEBP, quality: 100)
        file {
          url
        }
      }

      researchContentLongDescription {
        raw
      }
      articleDownloadLink {
        raw
      }
      downloadImage {
        description
        file {
          url
        }
      }
      documentOrCtaExternalLink
      document {
        description
        file {
          url
        }
      }
      ctaExternalLink{
        label
        id
        link {
          ... on ContentfulWebLink {
            url
            openInANewTab
          }
          ... on ContentfulComponentPdfWithUrl {
            pdfOrUrl
            url
            pdf {
              file {
                url
                fileName
              }
            }
          }
          ... on ContentfulInternalLink{
            pageName
            slug
            createdAt
            anchorLink
          }
        }
      }
      buttonLabel
      categoryTitle
      tagsTitle
      categoryList {
        categoryName
        contentful_id
        entryTitle
      }
      tagList {
        contentful_id
        entryTitle
        tagName
      }
      pardotForm {
        id
        contentful_id
        entryTitle
        header
        subHeader {
          raw
        }
        iFrameLink
        closeIcon{
          description
          file {
            url
          }
        }
      }
    }
  }

  fragment compImageWithButton on ContentfulCompImageWithButton {
    entryTitle
    downloadImage {
      description
      file {
        url
      }
    }
    buttonBackgroundColor
    buttonFontColor
    buttonBorderRadius
    buttonFontSize
    buttonPadding
    buttonPosition
    buttonPositionLeft
    buttonPositionTop
    imagePosition
    widthOfImage
    buttonBorder
    transform
    imageMaximumHeight
    ctaButtonEnabled
    imageName
    document {
      description
      file {
        url
      }
    }
    downloadButton {
      displayPrimaryCta
      labelForPrimaryCta
      primaryCtaShouldBeDisplayedAs
      primaryCtaShouldOpenInTheSameTab
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
