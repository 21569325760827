import { makeStyles } from '@material-ui/core/styles';

const optimiseImg="?fm=webp&q=100"
export const useStyles = makeStyles((theme) => ({
  'bg-cta-dot-matrix-img': (data) => ({
    '&::after': {
      background: `url(${data?.backgroundImage?.file?.url}${optimiseImg}) no-repeat`,
    },
  }),
  'bg-popup-close-icon': (data) => ({
    background: `url(${data?.pardotFromData?.closeIcon?.file?.url}${optimiseImg}) no-repeat`,
  }),
  'bg-dot-matrix-and-donut': (data) => ({
    '&::after': {
      background: `url(${data?.backgroundImage?.file?.url}${optimiseImg}) no-repeat`,
    },
    background: `url(${data?.leftDonut?.leftDonutBackgroundImage?.file?.url}${optimiseImg}) no-repeat`,
  }),
}));