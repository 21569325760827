import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  'bg-hero-dot-matrix-img': (images) => ({
    '&::before':{
      background: `url(${images?.researchGridBridgeImage?.file.url}?fm=webp&q=100) repeat`,
    },
    '&::after': {
      background: `url(${images?.smallDotMatrixBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
    },
  }),

}));
