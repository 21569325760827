class ResearchWordCount {
    static generateTotalContent(element,TotalContent) {
        element.forEach((el) => {
          el?.content.forEach((childElement) => {
            TotalContent = TotalContent + ' ' + childElement?.value?.trim();
          });
        });
        return TotalContent;
      }
    static WordCount(researchData) {
        let TotalContent = '';
        if (researchData?.researchContentLongDescription?.raw) {
            TotalContent = this.generateTotalContent(JSON.parse(researchData?.researchContentLongDescription?.raw).content);
          }
         return TotalContent;
    } 
}
export { ResearchWordCount };