import React from 'react';
import { Grid } from '@material-ui/core';
import CategoryAndTags from '../CategoryAndTags';
import SocialMedia from '../SocialMedia';
import './CategorySocialMedia.scss';
import Container from '@material-ui/core/Container';

const CategorySocialMedia = ({
  data,
  bucketname,
  type,
  path,
  wordCount,
  title,
  landingUrl,
  basePath,
  images,
  location
}) => {
  return (
    <Container maxWidth="xl" className="social-media-padding-none">
      <Grid container className="social-media">
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <CategoryAndTags data={data} landingUrl={landingUrl} basePath={basePath} />
          <SocialMedia
            siteurl={bucketname}
            type={type}
            wordcount={wordCount}
            title={title}
            pathName={path}
            images={images}
            basePath={basePath}
            location={location}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
export default CategorySocialMedia;
