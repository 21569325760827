import React from 'react';
import { Grid, Button, Slide, Dialog, DialogContent, Box } from '@material-ui/core';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useStyles } from './styles';
import './ImageWithDownloadButton.scss';
import { Link } from 'gatsby';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ImageWithDownloadButton({
  image,
  backgroundImage,
  buttonLabel,
  downloadButtonImage,
  altdownloadButtonImage,
  altimage,
  pardotFromData,
  showPardot,
  setShowPardot,
  leftDonut,
  pdfLink,
  documentOrCtaExternalLink,
  ctaExternalLink,
  basePath
}) {
  const backgroundImg = { backgroundImage, pardotFromData, leftDonut };
  const classes = useStyles(backgroundImg ? backgroundImg : '');
  const handleClose = () => {
    setShowPardot(false);
  };
  return (
    <Grid
      item
      xl={5}
      lg={5}
      md={12}
      sm={12}
      xs={12}
      className={clsx('supply-chain-img', 'supply-chain-image', classes['bg-cta-dot-matrix-img'])}
    >
      <div className="img-thumb-div" locId="labelForPrimaryCta">
        {documentOrCtaExternalLink == false ? (
          <>
          {ctaExternalLink?.link?.url ? (
            <Link to={ctaExternalLink?.link?.url} target="_blank" tabIndex="0" download>
              <img src={image} className="img-thumb" alt={altimage} />
              <div className="aftertxt">
                <Button variant="contained" className="dwn-btn-white" tabIndex="0">
                  <span className="download_icon">
                    <img src={downloadButtonImage} alt={altdownloadButtonImage} />
                  </span>
                  <span className="btnText">{buttonLabel}</span>
                </Button>
              </div>
            </Link>
          ):(
            <Link to={`/${basePath}/` + ctaExternalLink?.link?.slug} target="_blank" tabIndex="0" download>
              <img src={image} className="img-thumb" alt={altimage} />
              <div className="aftertxt">
                <Button variant="contained" className="dwn-btn-white" tabIndex="0">
                  <span className="download_icon">
                    <img src={downloadButtonImage} alt={altdownloadButtonImage} />
                  </span>
                  <span className="btnText">{buttonLabel}</span>
                </Button>
              </div>
            </Link>
          )}          
          </>
        ):(
          <>
            {pardotFromData ? (
                <div>
                  <img src={image} className="img-thumb" alt={altimage} />
                  <div className="aftertxt">
                    <Button
                      variant="contained"
                      className="dwn-btn-white"
                      tabIndex="0"
                      onClick={() => setShowPardot((prev) => !prev)}
                    >
                      <span className="download_icon">
                        <img src={downloadButtonImage} alt={altdownloadButtonImage} />
                      </span>
                      <span className="btnText">{buttonLabel}</span>
                    </Button>
                  </div>
                </div>
              ) : (
                <Link to={pdfLink} target="_blank" tabIndex="0" download>
                  <img src={image} className="img-thumb" alt={altimage} />
                  <div className="aftertxt">
                    <Button variant="contained" className="dwn-btn-white" tabIndex="0">
                      <span className="download_icon">
                        <img src={downloadButtonImage} alt={altdownloadButtonImage} />
                      </span>
                      <span className="btnText">{buttonLabel}</span>
                    </Button>
                  </div>
                </Link>
              )}
          </>
        )}
        
      </div>
      {showPardot && pardotFromData && (
        <Dialog
          open={showPardot}
          TransitionComponent={Transition}
          onClose={handleClose}
          maxWidth="md"
        >
          <Box
            className={clsx('request-form-popup-overlay donut', classes['bg-dot-matrix-and-donut'])}
          >
            <div
              className={clsx('popup-close-icon', classes['bg-popup-close-icon'])}
              onClick={handleClose}
              tabindex="0"
            ></div>
            <DialogContent className="dialog-content">
              <Grid container>
                <Grid items xl={6} lg={6} md={12} sm={12} xs={12}>
                  <div className="title" locId="header">
                    {pardotFromData?.header}
                  </div>
                  {pardotFromData?.subHeader?.raw &&
                    documentToReactComponents(JSON.parse(pardotFromData?.subHeader?.raw))}
                </Grid>
                <Grid items xl={6} lg={6} md={12} sm={12} xs={12} className="form-div">
                  <div className="form_section">
                    {pardotFromData?.iFrameLink && (
                      <iframe
                        src={pardotFromData?.iFrameLink}
                        width="100%"
                        height="100%"
                        type="text/html"
                        frameborder="0"
                        scrolling="no"
                        allowTransparency="true"
                        style={{ border: 0 }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </Grid>
  );
}
