import React, { useState } from 'react';
import './ResearchContentHeader.scss';
import { Grid, Button } from '@mui/material';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useStyles } from './styles';
import clsx from 'clsx';
import ImageWithDownloadButton from '../../ImageWithDownloadButton';
import { DateFormatUtils } from '../../../utils/date-formatter';

export default function ResearchContentHeader({ data, images, basePath }) {
  const classes = useStyles(images);
  const imageOptimum = '?fm=webp&q=100';
  const { date, month, year } = DateFormatUtils.DateMonthYearFormatter(data?.dateOfPublishing);
  const [showPardot, setShowPardot] = useState(false);
  return (
    <>
      <Grid container className="research-content-header-cls">
        {/* **********************    Text-with-Image-row    ********************** */}
        <Grid container className="research-header-container">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="research-content-text-img">
            <Grid
              item
              xl={5}
              lg={5}
              md={12}
              sm={12}
              xs={12}
              className="research-content-header-txt"
            >
              <div className="research-content-header-txt-div" locId="resourceType">
                <p className="research-content-header-date">
                  {data.resourceType} / {date} {month} {year}
                </p>
                <h1 className="research-content-header-title" locId="researchTitle">
                  {data.researchTitle}
                </h1>
              </div>
            </Grid>

            <Grid
              item
              xl={7}
              lg={7}
              md={12}
              sm={12}
              xs={12}
              className={clsx('research-content-header-heroimg', classes['bg-hero-dot-matrix-img'])}
              locId="headerImage"
            >
              <img
                src={data?.headerImage?.file?.url + imageOptimum}
                alt={data?.headerImage?.description}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* **********************   Text-row-2    ********************** */}
        <Grid className="research-content-padding">
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
            <ImageWithDownloadButton
              image={data?.downloadImage?.file?.url + imageOptimum}
              backgroundImage={images?.dotMatrixBackgroundImage}
              leftDonut={images}
              pdfLink={data?.document?.file?.url}
              buttonLabel={data?.buttonLabel}
              downloadButtonImage={images?.downloadButtonImage?.file?.url + imageOptimum}
              altdownloadButtonImage={images?.downloadButtonImage?.description}
              altimage={data?.downloadImage?.description}
              pardotFromData={data?.pardotForm}
              showPardot={showPardot}
              setShowPardot={setShowPardot}
              documentOrCtaExternalLink={data?.documentOrCtaExternalLink}
              ctaExternalLink={data?.ctaExternalLink}
              basePath={basePath}
            />

            <Grid item container xl={7} lg={7} md={12} sm={12} xs={12}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="research-content-rich-txt"
                locId="researchContentLongDescription"
              >
                {data?.researchContentLongDescription?.raw &&
                  documentToReactComponents(JSON.parse(data.researchContentLongDescription.raw))}
                {data?.documentOrCtaExternalLink == false ? (
                  <>   
                  {data?.ctaExternalLink?.link?.url ? (
                      <Button
                      variant="contained"
                      className="research-dwn-btn"
                      locId="buttonLabel"
                      href={data?.ctaExternalLink?.link?.url}
                      target="_blank"
                      >
                      {data.buttonLabel}
                    </Button>
                  ):(
                     <Button
                      variant="contained"
                      className="research-dwn-btn"
                      locId="buttonLabel"
                      href={`/${basePath}/` + data?.ctaExternalLink?.link?.slug}
                      target="_blank"
                      >
                      {data.buttonLabel}
                    </Button>  
                  )}
                  </>
                ): (
                  <>
                  {data?.pardotForm ? (
                      <Button
                        variant="contained"
                        className="research-dwn-btn"
                        locId="buttonLabel"
                        onClick={() => setShowPardot(true)}
                      >
                        {data.buttonLabel}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="research-dwn-btn"
                        locId="buttonLabel"
                        href={data?.document?.file?.url}
                        target="_blank"
                      >
                        {data.buttonLabel}
                      </Button>
                    )}
                  </>
                )}                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
