
class DateFormatUtils {
    static DateMonthYearFormatter(publisheddate) {
        const formatDate = new Date(publisheddate);
        const arr = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        let date = formatDate.getDate();
        if (date < 10) {
          date = '0' + date;
        }
        const month = arr[formatDate.getMonth()];
        const year = formatDate.getFullYear();
        return {date,month,year};
    }

}
export { DateFormatUtils };

