import React from 'react';
import './CategoryAndTags.scss';
import { Link } from 'gatsby';
import { Grid } from '@mui/material';
import { urlgenerator } from '../../utils/url-generator'

const CategoryAndTags = ({ data, landingUrl, basePath }) => {
  return (
    <Grid item container xl={6} lg={6} md={9} sm={9} xs={12}>
      {(data?.categoryTitle || data?.tagsTitle) &&
        <div className="category-container">
          <div className="category-para">
            <span className="cat-main-title" locId="categoryTitle">
              {data?.categoryTitle}
              {data?.categoryTitle ? ':' : null}{' '}
              <span className="cat-main-text">
                {data?.categoryList?.map((category, index, arr) => (
                  <Link
                    to={`${urlgenerator(basePath, landingUrl)}?category=${category.contentful_id}`}
                    locId="category"
                    key={category.contentful_id}
                  >
                    {arr.length - 1 !== index
                      ? `${category.categoryName}, `
                      : `${category.categoryName}`}
                  </Link>
                ))}
              </span>
            </span>
          </div>
          <div className="category-para">
            <span className="cat-main-title" locId="tagsTitle">
              {data?.tagsTitle}{data?.tagsTitle ? ':' : null}{' '}
              <span className="cat-main-text">
                {data?.tagList?.map((tag, index, arr) => (
                  <Link
                    to={`${urlgenerator(basePath, landingUrl)}?tag=${tag.contentful_id}`}
                    locId="tags"

                    key={tag.contentful_id}
                  >
                    {arr.length - 1 !== index ? `${tag.tagName}, ` : `${tag.tagName}`}
                  </Link>
                ))}
              </span>
            </span>
          </div>
        </div>
      }
    </Grid>
  );
};
export default CategoryAndTags;
